import React from "react";
import { Container, Layout, SEO } from "../../components/common";
import { Wrapper, Grid, Item, List, ContainerIcon, ContainerT } from "./styles";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { Player, BigPlayButton } from "video-react";

import ReactPlayer from "react-player";

import app from "../../assets/logos/app.jpg";
import { Intro } from "../../components/landing";

import BiometriaAh30 from "../../assets/videos/biometria_ah30.mp4";
import BiometriaAh10 from "../../assets/videos/biometria_ah10.mp4";
import Mobile from "../../assets/videos/registra_ponto_mobile.mp4";

const Tutorial = () => {
  const history = useHistory();

  return (
    <Layout>
      <ContainerT>
        <Wrapper as={Container} id="projects">
          <ContainerIcon>
            <FiArrowLeft onClick={() => history.push("/")} />
            <h2>Tutoriais</h2>
          </ContainerIcon>
          <Grid>
            <Item>
              <Player src={BiometriaAh10}>
                <BigPlayButton position="center" />
              </Player>
              <h3 style={{ marginTop: 20 }}>
                Cadastrar Biometria no REP - AH10
              </h3>
              <p>
                Procedimento de Cadastro da Biometria do Funcionário no Relógio
                - Ahgora AH10
              </p>
            </Item>{" "}
            <Item>
              <Player src={BiometriaAh30}>
                <BigPlayButton position="center" />
              </Player>
              <h3 style={{ marginTop: 20 }}>
                Cadastrar Biometria no REP - AH30
              </h3>
              <p>
                Procedimento de Cadastro da Biometria do Funcionário no Relógio
                - Ahgora AH30
              </p>
            </Item>{" "}
            <Item style={{ height: 500 }}>
              <Player src={Mobile} fluid={false} height="70%">
                <BigPlayButton position="center" />
              </Player>
              <h3 style={{ marginTop: 20 }}>
                Registrar Ponto no Aplicativo Ponto Fácil
              </h3>
              <p>
                Procedimento para Registrar Ponto e Cadastrar o número do
                whatsapp para receber o compravante.
              </p>
            </Item>{" "}
          </Grid>
        </Wrapper>
      </ContainerT>
    </Layout>
  );
};

export default Tutorial;

import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
`;

export const Bloco = styled.div`
    text-align: center;

    p{
        padding: 10px;
    }
`;
import React from "react";
import { Container } from "../../../components/common";
import { Wrapper, SkillsWrapper, Details } from "./styles";

export const Skills = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container} style={{ marginTop: -150 }}>
      <Details>
        <h1 style={{ textAlign: "center" }}>Sobre</h1>
        <p style={{ textAlign: "center", fontSize: 25 }}>
          A Proinddy está a mais de 20 anos gerando soluções para a automação na
          gestão de carga horária laboral. Nossos produtos estão presentes em
          mais de 80 instituições espalhadas por todo o estado do Maranhão.
          Nossos serviços incluem uma assistência técnica completa, presencial
          ou a distância, prestada pelos nossos profissionais treinados e
          dispostos a atendê-lo. Com isso, desejamos contribuir cada vez mais
          para que nossas instituições funcionem em acordo com as portarias do
          Ministério do Trabalho, e seus gestores possam ficar tranquilos para
          trabalhar e expandirem seus negócios cada vez mais.
        </p>
      </Details>
    </SkillsWrapper>
  </Wrapper>
);

import styled from "styled-components";
import detailsIllustration from "../../../assets/illustrations/details.svg";

export const Wrapper = styled.div`
  background-image: url(${detailsIllustration});
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
`;

export const SkillsWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;
  padding-left: 2rem;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: #555;
  }
  h2 {
    color: #3338;
  }

  ul {
    li {
      font-size: 20px;
      font-weight: 600;
      color: #2228;
    }

    h4 {
      font-weight: normal;
      font-size: 20px;
      color: #3339;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`;

export const ContainerHTitle = styled.div`
  svg {
    position: absolute;
    font-size: 40px;
    transition: 0.3s;

    :hover {
      cursor: pointer;
      font-size: 43px;
      margin-left: 5px;
    }
  }

  @media (max-width: 960px) {
    margin-top: -70px;
    svg {
      position: relative;
      margin-bottom: 10px;
    }
  }
`;

import React from "react";
import { Container } from "../../../components/common";
import { Wrapper, Details, Thumbnail } from "./styles";
import ContactForm from "./ContactForm";

export const Contact = () => (
  <Wrapper as={Container} id="contact">
    <Thumbnail>
      <h2>Contato</h2>
    </Thumbnail>
    <Details>
      <ContactForm />
    </Details>
  </Wrapper>
);

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "../node_modules/video-react/dist/video-react.css"; // import css

import Home from "./page/home";
import Tutorial from "./page/tutorial";
import Privacidade from "./page/privacidade";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/tutorial" exact component={Tutorial} />
        <Route path="/privacidade" exact component={Privacidade} />
        <Redirect from="/*" to="/" />
      </Switch>
    </Router>
  );
}

export default App;

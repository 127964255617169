import React from "react";
import { Layout, SEO } from "../../components/common";
import {
  Contact,
  Intro,
  Localizacao,
  Projects,
  Skills,
} from "../../components/landing";

const Home = () => {
  return (
    <Layout>
      <SEO />
      <Intro />
      <Projects />
      <Skills />
      <Contact />
      <Localizacao />
    </Layout>
  );
};

export default Home;

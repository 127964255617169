import React from "react";
import { useHistory } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Wrapper } from "./styles";
import "./styles.css";
const NavbarLinks = ({ desktop }) => {
  const history = useHistory();
  return (
    <Wrapper desktop={desktop}>
      <AnchorLink id="link" href="#projects">
        Produtos e Serviços
      </AnchorLink>
      <a id="link" href="tutorial">
        Tutoriais
      </a>
      <AnchorLink id="link" href="#about">
        Sobre
      </AnchorLink>
      <a id="link" href="privacidade">
        Privacidade
      </a>
      <AnchorLink id="link" href="#contact">
        Contato
      </AnchorLink>
      <a
        id="link"
        href="http://www.proinddy.com.br/acme2/sis/test/"
        target="_blank"
      >
        Login
      </a>
    </Wrapper>
  );
};

export default NavbarLinks;

import React from "react";
import axios from "axios";
import { Formik, Form, FastField, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Input } from "../../../../components/common";
import { Error, Center, InputField } from "./styles";

export default () => (
  <Formik
    initialValues={{
      name: "",
      email: "",
      message: "",
      telefone: "",
      success: false,
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required("O campo Nome completo é obrigatório"),
      email: Yup.string()
        .email("Invalid email")
        .required("O campo E-mail é obrigatório"),
      telefone: Yup.string().required("O Campo Telefone é obrigatório"),
      message: Yup.string().required("O campo da mensagem é obrigatório"),
    })}
    onSubmit={async (
      { name, email, message },
      { setSubmitting, resetForm, setFieldValue }
    ) => {
      try {
        await axios({
          method: "POST",
          url: ``,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            name,
            email,
            message,
          }),
        });
        setSubmitting(false);
        setFieldValue("success", true);
        setTimeout(() => resetForm(), 6000);
      } catch (err) {
        setSubmitting(false);
        setFieldValue("success", false);
        alert("Alguma coisa deu errado. Por favor tente outra vez!"); // eslint-disable-line
      }
    }}
  >
    {({ values, touched, errors, setFieldValue, isSubmitting }) => (
      <Form>
        <InputField>
          <Input
            as={FastField}
            type="text"
            name="name"
            component="input"
            aria-label="name"
            placeholder="Nome*"
            error={touched.name && errors.name}
          />
          <ErrorMessage component={Error} name="name" />
        </InputField>
        <InputField>
          <Input
            id="email"
            aria-label="email"
            component="input"
            as={FastField}
            type="email"
            name="email"
            placeholder="E-mail*"
            error={touched.email && errors.email}
          />
          <ErrorMessage component={Error} name="email" />
        </InputField>
        <InputField>
          <Input
            id="telefone"
            aria-label="telefone"
            component="input"
            as={FastField}
            type="text"
            name="telefone"
            placeholder="Telefone*"
            error={touched.telefone && errors.telefone}
          />
          <ErrorMessage component={Error} name="telefone" />
        </InputField>
        <InputField>
          <Input
            as={FastField}
            component="textarea"
            aria-label="message"
            id="message"
            rows="8"
            type="text"
            name="message"
            placeholder="Mensagem*"
            error={touched.message && errors.message}
          />
          <ErrorMessage component={Error} name="message" />
        </InputField>
        {values.success && (
          <InputField>
            <Center>
              <h4>
                Sua mensagem foi enviada com sucesso, retornarei o mais rápido
                possível!
              </h4>
            </Center>
          </InputField>
        )}
        <Center>
          <Button secondary type="submit" disabled={isSubmitting}>
            Enviar
          </Button>
        </Center>
      </Form>
    )}
  </Formik>
);

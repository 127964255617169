import React from "react";
import { Layout, Container } from "../../components/common";

import { Privacidade as Private } from "../../components/landing/Privacidade";
import { ContainerP, Wrapper } from "./styles";

const Privacidade = () => {
  return (
    <Layout>
      <ContainerP>
        <Wrapper as={Container}>
          <Private />
        </Wrapper>
      </ContainerP>
    </Layout>
  );
};

export default Privacidade;

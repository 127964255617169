module.exports = {
  defaultTitle: 'Proinddy',
  logo: 'http://srv.proinddy.com.br/img/Logo-Proinddy.png',
  author: 'Proinddy Serviços e Comercio',
  url: 'http://www.proinddy.com.br',
  legalName: 'Proinddy',
  defaultDescription: 'Proind',
  socialLinks: {
   
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@smakosh',
  },
  address: {
    city: 'São Luis',
    region: 'Maranhão',
    country: 'Brasil',
    zipCode: '65036-490',
  },
  contact: {
    email: 'suporte@proinddy.com.br',
    phone: '+55 98 8886-0465',
  },
  foundingDate: '2020',
};

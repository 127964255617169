import React from "react";
import { Container } from "../../../components/common";
import NavbarLinks from "../NavbarLinks";
import { Wrapper } from "./styles";

const Navbar = () => (
  <Wrapper as={Container}>
    <img
      src="http://srv.proinddy.com.br/img/Logo-Proinddy.png"
      alt="logo"
      width="120px"
    />
    <NavbarLinks desktop />
  </Wrapper>
);

export default Navbar;

import styled from "styled-components";
import overlayIllustration from "../../assets/illustrations/overlay.svg";

export const ContainerP = styled.div`
  padding-bottom: 4rem;
  background-image: url(${overlayIllustration});
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
`;

export const Wrapper = styled.div`
  padding: 2rem 0;
`;

export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;

  svg {
    margin-right: 20px;
    margin-top: 2px;
    font-size: 25px;

    :hover {
      cursor: pointer;
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  transition: 500ms;
  border-radius: 5px;

  &:hover {
    box-shadow: 0px 10px 10px 1px #7975;
  }

  img {
    margin-bottom: 5px;
    opacity: 0.8;
    transition: 500ms;

    &:hover {
      opacity: 1;
    }
  }

  h4 {
    color: #212121;
  }
  h3 {
    color: #797991;
    font-size: 25px;
    text-align: center;

    a {
      color: #797991;
    }
  }

  p {
    padding: 0px 10px 1px 10px;
    font-size: 18px;
    text-align: center;
  }
`;

export const Content = styled.div`
  padding: 1rem 0;
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    &:first-child {
      margin-right: 0.5rem;
    }

    img {
      margin: 0;
    }

    span {
      color: #000;
      margin-left: 0.5rem;
    }
  }
`;

export const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  ul {
    padding: 10px;
    margin-top: 10px;

    li {
      color: #444;
      font-size: 18px;
      text-align: left;
      white-space: initial;
    }
  }
`;
export const List = styled.ul`
  margin-top: -20px;

  li {
    font-size: 18px;
    color: #444;
  }
`;

import React from 'react';

import { Container, Bloco } from './styles';

export const Localizacao = () => (
    <Container>
        <Bloco>
        <h2>Localização</h2>
        <p>Rua Padre Manoel da Nóbrega, 39, Edificio Florência, Sala 2 - Apeadouro - São Luís - MA</p>
        </Bloco>
        <Bloco>
        <h2>PROINDDY SERVIÇOS E COMERCIO</h2>
        <p>Tel: (98) 3221-0897/ 3232-0115/ 98886-0465</p>
        <p>Henrique Sergio Santos - Proprietário</p>
        </Bloco>
    </Container>
)
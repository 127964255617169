import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { Container } from "../../../components/common";
import { Wrapper, SkillsWrapper, Details, ContainerHTitle } from "./styles";

export const Privacidade = () => {
  const history = useHistory();
  return (
    <Wrapper id="private">
      <SkillsWrapper as={Container}>
        <Details>
          <ContainerHTitle>
            <FiArrowLeft onClick={() => history.push("/")} id="back" />
            <h1 style={{ textAlign: "center" }}>
              Política de Privacidade Proinddy
            </h1>
          </ContainerHTitle>

          <ul>
            <li>Última atualização: 02 de Setembro de 2020.</li>
            <h4>
              Esta Política de Privacidade descreve como a Proinddy
              (proinddy.com.br) lida com suas informações pessoais quando você
              usa o App Proinddy Registro de Ponto, App Proinddy Consulta de
              Batidas, Sistema Web Gerenciamento de Relógios e Dispositivos,
              Sistema Ponto Fácil de Apuração (aqui denominados como
              “Serviços”).
            </h4>
            <h4>
              Esta Política de Privacidade atém-se a dar os detalhes
              provenientes não somente da relação entre{" "}
              <span style={{ fontWeight: "bold" }}>
                USUÁRIO e a PROINDDY, mas também do USUÁRIO e o APP PROINDDY
                REGISTRO DE PONTO, APP PROINDDY CONSULTA DE BATIDAS, SISTEMA WEB
                GERENCIAMENTO DE RELÓGIOS E DISPOSITIVOS, e SISTEMA PONTO FÁCIL
                DE APURAÇÃO
              </span>{" "}
              , abordando o uso em geral das informações que o USUÁRIO dispõe e
              como tal informação será utilizada dentro da ferramenta.{" "}
              <span
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "#555",
                  borderBottomStyle: "solid",
                }}
              >
                {" "}
                Caso queira entender sobre Direitos dos Titulares, Forma de
                Armazenamento, Dados Coletados, Forma de Coleta, Finalidade de
                Coleta e outras disposições relativas à LGPD (Lei Geral de
                Proteção de Dados), consulte nossa{" "}
                <a href="#">Política de Segurança de Dados</a>
              </span>
            </h4>
            <li>Localização Geográfica</li>
            <h4>
              A Proinddy é uma empresa com sede no Brasil. Ao usar nossos
              serviços, você autoriza a Proinddy a usar suas informações de
              acordo com as leis do Brasil. Oferecemos o serviço em várias
              regiões geográficas. Definimos uma região geográfica como o local
              em que um usuário está localizado.
            </h4>
            <li>Usuários no Brasil</li>
            <h4>
              Para usuários no Brasil, processamos dados em data centers
              localizados no Brasil e nos EUA.{" "}
              <span
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: "#555",
                  borderBottomStyle: "solid",
                }}
              >
                Adotamos proteções físicas, técnicas e organizacionais em
                conformidade com a Lei Geral de Proteção de Dados (LGPD).
                Consulte nossa <a href="#">Política de Segurança de Dados</a>{" "}
                para informações mais detalhadas sobre dados dos titulares
              </span>
              . Cumprimos também as leis estaduais e federais que regem a
              proteção de informações pessoais. Se você tiver quaisquer
              perguntas ou comentários sobre esta Política de Privacidade, entre
              em contato conosco: <a href="#">suporte@proinddy.com.br</a>
            </h4>
            <li>1. INFORMAÇÃO PESSOAL</li>
            <h4>
              Como provedor de serviços de controle de ponto, nos esforçamos
              para coletar a quantidade mínima de informações pessoais
              necessárias para operar nossos serviços. Isso geralmente significa
              trocas difíceis entre as informações que coletamos e o desempenho
              de nossos Serviços. Acreditamos em um diálogo aberto, e esta
              Política de Privacidade é um documento em evolução. Agradecemos
              seus pensamentos e feedback sobre como estamos indo. As
              informações que tratamos são coletadas por terceiros, que incluem
              seu nome, telefone, CPF, PIS e, em algumas circunstâncias, podem
              incluir dados operacionais, conforme descrito abaixo. Também
              podemos coletar informações pessoais que você nos fornece se você
              se comunicar conosco, por exemplo, para solicitar suporte.
              Coletamos, usamos e divulgamos suas Informações Pessoais, conforme
              necessário, a fim de fornecer a você os Serviços e para os outros
              fins identificados descritivamente em nossa{" "}
              <a href="#">Política de Segurança de Dados</a>.
            </h4>
            <li>1.2 SEUS DADOS EM NOSSOS SERVIÇOS</li>
            <h4>
              {" "}
              Os nossos Serviços foram cuidadosamente projetados para que você e
              somente as pessoas essencialmente autorizadas possam acessar as
              informações armazenadas. Os profissionais que podem acessar suas
              informações o fazem apenas de maneira suficiente a viabilizar a
              prestação de serviços para nossos usuários. Todos seus dados, como
              senhas, notas e quaisquer outros tipos de dados armazenados por
              você nos Serviços, são criptografados de ponta a ponta, dispondo
              assim da segurança por meio da anonimização. Seus dados
              criptografados serão armazenados em nossos servidores para que
              você possa sincronizar entre dispositivos e aplicativos. Mesmo que
              seus dados sejam armazenados em nossos servidores, eles somente
              estarão acessíveis quando você os desbloquear com sua Senha de
              Chave. A Proinddy não possui acesso aos dados inseridos por você,
              essas são suas informações e só você pode adicioná-las, excluí-las
              e modificá-las a qualquer momento. Se você acredita que suas
              informações podem não estar seguras por qualquer motivo, entre em
              contato imediatamente em <a href="#">suporte@proinddy.com.br</a>.
            </h4>
            <li>1.3 DADOS DA CONTA</li>
            <h4>
              Quando você cria ou atualiza sua conta de usuário do Serviço,
              coletamos e armazenamos os “Dados da Conta”. Os Dados da Conta
              estão listados abaixo e são usados por nós para os fins descritos:
            </h4>
            <li>Dados da Conta. Para que o usamos?</li>
            <h4>
              Endereço de e-mail utilizado para se Cadastrar. O seu telefone
              para fornecer suporte e uso de sua conta, comunicações gerais,
              recibos de compras e notícias ocasionais sobre produtos.
              Confirmação de que seu endereço de email é válido.
            </h4>
            <li>1.4 DADOS OPERACIONAIS</li>
            <h4>
              A Proinddy também coleta e armazena "Dados Operacionais"
              necessários para operar nossos Serviços. Estes são os dados que
              coletamos e armazenamos quando você se conecta aos nossos
              Serviços. Os Dados Operacionais estão listados abaixo na sua
              totalidade e são utilizados por nós para os seguintes fins:
            </h4>
            <li>Dados Operacionais. Para que o usamos?</li>
            <h4>
              <span style={{ fontWeight: "bolder" }}>
                App Proinddy Registro de Pontos:
              </span>{" "}
              Para funcionalidade do serviço, suporte ao usuário, solução de
              problemas e planejamento do produto.{" "}
              <span style={{ fontWeight: "bolder" }}>
                App Proinddy Consulta de Batidas:
              </span>{" "}
              Suporte ao usuário e solução de problemas. Sistema Web
              Gerenciamento de Relógios e Dispositivos: Satisfação do cliente,
              suporte e planejamento de produtos. <br />
              <span style={{ fontWeight: "bolder" }}>
                Sistema Ponto Fácil de Apuração:
              </span>
              Satisfação do cliente, suporte e planejamento de produtos.
            </h4>
            <li>1.5 DADOS PESSOAIS E FINANCEIROS COLETADOS NO PAGAMENTO</li>
            <h4>
              Fazer uma compra com cartão de crédito em qualquer um dos Serviços
              resultará na troca de Informações Pessoais com processadores de
              pagamento.
            </h4>
            <li>Transações com Cartões de Crédito</li>
            <h4>
              A Proinddy processa as informações de pagamento com cartão de
              crédito de forma segura por meio do PagSeguro, um processador de
              pagamento de terceiros, cujo uso de suas informações pessoais é
              regido por sua <a href="#">Política de Segurança de Dados</a>. A
              PagSeguro pode armazenar Informações Pessoais associadas às suas
              transações financeiras na Área Econômica Européia (“EEE”) ou nos
              Estados Unidos da América. Nesse caso, essas informações estarão
              sujeitas às leis da jurisdição em que são mantidas. Quando você
              paga com cartão de crédito, a Proinddy coleta e armazena as
              seguintes informações, usadas para os fins descritos:
            </h4>
            <li>Dados do Cartão de Crédito, para que os usamos?</li>
            <h4>
              Sobrenome do titular do cartão. Para uso na prevenção de fraudes
              no cartão de crédito.
              <br />
              <br />
              Data de uso do cartão. Para uso na prevenção de fraudes no cartão
              de crédito.
              <br />
              <br />
              Últimos quatro números do cartão de Crédito. Para uso na prevenção
              de fraudes no cartão de crédito.
              <br />
              <br />A Proinddy não armazena, mas pode efetuar login e visualizar
              com segurança, as seguintes informações através do nosso
              processador de pagamento terceirizado, PagSeguro:
            </h4>
            <li>Dados de pagamento. Para que os usamos?</li>
            <h4>
              {" "}
              • Endereço de cobrança do cartão. Para uso na prevenção de fraudes
              com cartão de crédito.
              <br />
              • Expiração do cartão. Para uso na prevenção de fraudes com cartão
              de crédito.
              <br />• Últimos quatro números de cartão de crédito. Para uso na
              prevenção de fraudes com cartão de crédito.
            </h4>

            <h4>
              A Proinddy nunca armazena seu número de cartão de crédito
              completo. Para proteger a segurança de suas informações de
              pagamento, adotamos todas as medidas de segurança e autenticação
              de fatores múltiplos disponíveis em nossos processadores de
              pagamento. A Proinddy opera exclusivamente com processadores de
              pagamento compatíveis com PCI. Somente nossos processadores de
              pagamento têm a capacidade de coletar, usar e acessar as
              informações completas do cartão de crédito e outras informações
              financeiras. Eles podem usar essas informações apenas com a
              finalidade de cobrar e faturar os Serviços (pagos) e conforme
              exigido pela Lei Geral de Proteção de Dados (LGPD).
            </h4>
            <li>1.6 DIVULGAÇÃO DE INFORMAÇÃO PESSOAL A TERCEIROS</li>
            <h4>
              Exceto conforme descrito abaixo ou conforme exigido ou permitido
              por lei, a Proinddy NÃO divulgará suas Informações Pessoais a
              terceiros sob nenhuma circunstância, sem o seu consentimento.
            </h4>
            <h4>
              A Proinddy divulgará seus dados para a{" "}
              <a href="#">Biometria Universal</a>, mediante aceitação do usuário
              por meio de sua anuência no momento da contratação feita pelo
              Controlador, o qual comprovará que o Usuário procedeu à
              autorização para tal finalidade, de forma livre, informada e
              inequívoca. Ressaltamos que a Biometria Universal é um feature
              utilizado nos Serviços para garantir mais conforto e satisfação ao
              cliente, não sendo obrigatório sua anuência para que o serviço
              seja prestado.
            </h4>
            <h4>
              Ao utilizar as funcionalidades dos aplicativos dos Serviços, o
              Usuário terá que se utilizar de serviços de terceiros para o
              processamento de alguns dados, como de geolocalização, detectada a
              partir do sistema utilizado por cada Usuário em seu dispositivo
              móvel, e, se aplicável, a coleta de dados biométricos, como o
              reconhecimento facial por fotografia, estando ciente a CONTRATANTE
              e demais Usuários de que a coleta e tratamento destas informações
              está sujeito à política de privacidade e termos de uso do
              desenvolvedor do sistema utilizado. Dentro do site/aplicativo, os
              dados de geolocalização e biométricos compartilhados pelos
              Usuários serão coletados e tratados unicamente para os fins
              descritos nestes Termos, e mediante o prévio, informado e expresso
              consentimento do Usuário com o compartilhamento de suas
              informações. Este consentimento será disponibilizado para o
              usuário no primeiro uso do aplicativo.
            </h4>
            <h4>
              A Proinddy divulgará também suas informações pessoais a provedores
              de serviços terceirizados (por exemplo, processadores de pagamento
              conforme descrito acima) na extensão mínima necessária para
              fornecer os Serviços; nesse caso, usamos meios contratuais ou
              outros para garantir que haja um nível comparável de proteção para
              qualquer informação pessoal que seja processada por terceiros por
              nós.
            </h4>
            <h4>
              Caso seja exigido que a Proinddy cumpra a aplicação da lei quando
              recebermos intimações, mandados ou outros documentos legais
              válidos sob a jurisdição brasileira, divulgaremos as Informações
              Pessoais somente na extensão legalmente exigida.
            </h4>
            <h4>
              Se a estrutura da nossa organização mudar (ou seja, em caso de
              passarmos por uma reestruturação ou sermos adquiridos), poderemos
              precisar migrar suas Informações Pessoais para terceiros
              relacionados a uma transação comercial, mas garantiremos que tal
              terceiro tenha firmado um contrato segundo o qual o uso de suas
              informações pessoais está relacionado apenas aos propósitos
              necessários para a transação e o terceiro concorda em proteger
              suas informações pessoais por meio de proteções de segurança
              apropriadas.
            </h4>
            <h4>
              Além disso, não divulgaremos informações sobre senhas, cartões de
              crédito ou outros dados que nossos usuários nos informem,{" "}
              <span
                style={{
                  fontWeight: "bolder",
                  borderBottomWidth: 1,
                  borderBottomColor: "#555",
                  borderBottomStyle: "solid",
                }}
              >
                pois a PROINDDY NÃO possui acesso a essas informações.
              </span>
            </h4>

            <li>
              2. COMPROMISSO COM OS PRINCÍPIOS DA INFORMAÇÃO PESSOAL (LGPD)
            </li>
            <h4>
              Qualquer informação pessoal que você fornecer à Proinddy será
              administrada de acordo com os seguintes princípios:
            </h4>
            <li>2.1 DA RESPONSABILIDADE</li>
            <h4>
              A Proinddy é responsável pelas Informações Pessoais sob nosso
              controle e designou uma ou mais pessoas para supervisionar a
              conformidade com a privacidade dos Serviços. Se você tiver alguma
              dúvida, preocupação ou reclamação sobre como suas Informações
              Pessoais são tratadas ou perguntas sobre nossa Política de
              <a href="#">Privacidade ou Política de Segurança</a>, entre em
              contato conosco pelo <a href="#">suporte@proinddy.com.br</a>.
            </h4>
            <li>2.2 IDENTIFICAÇÃO DE PROPÓSITOS E FINALIDADE</li>
            <h4>
              A Proinddy explicará os propósitos para os quais suas Informações
              Pessoais são coletadas anteriormente ou no momento da coleta. Se
              suas informações pessoais forem usadas para um propósito não
              identificado anteriormente (ou seja, um objetivo diferente dos
              identificados acima), nós o identificaremos antes do uso e, a
              menos que o novo objetivo seja exigido por lei, obteremos seu
              consentimento antes de usar as informações para esse fim. Para
              mais detalhes sobre identificação de Propósitos e Finalidade,
              consulte nossa{" "}
              <a href="#">Privacidade ou Política de Segurança</a>.
            </h4>
            <li>2.3 BASES LEGAIS PARA TRATAMENTO DE DADOS</li>
            <h4>
              Salvo disposição de lei em contrário, obteremos seu consentimento
              (ou outra base legal melhor aplicável) sempre que coletarmos,
              usarmos ou divulgarmos suas Informações Pessoais ou fizermos
              alterações nos Dados da Conta que armazenamos. Seu consentimento
              deverá ser expresso, informado e inequívoco. Você tem o direito de
              retirar seu consentimento para a coleta, uso ou divulgação de suas
              informações pessoais. Para exercer seu direito de retirar o
              consentimento ou fazer perguntas sobre suas informações pessoais,
              entre em contato com nosso DPO ou nosso Comitê de Segurança (
              <a href="#">suporte@proinddy.com.br</a>)
            </h4>
            <li>2.4 LIMITANDO A COLETA</li>
            <h4>
              Não coletamos seus dados de forma indiscriminada, e limitamos a
              coleta às informações mínimas necessárias para operar nossos
              Serviços. Ao limitar a coleta de Informações Pessoais, ajudamos a
              proteger a privacidade e a segurança de suas Informações Pessoais.
              A Proinddy respeita a privacidade dos seus visitantes online e dos
              clientes dos seus produtos e serviços, e cumpre com as leis
              aplicáveis relativas à proteção da privacidade do utilizador,
              incluindo, sem limitação, a Lei Geral de Proteção de Dados (LGPD).
            </h4>
            <li>2.5 LIMITANDO O USO, A DIVULGAÇÃO E A RETENÇÃO</li>
            <h4>
              Não usaremos ou divulgaremos seus dados pessoais para qualquer
              finalidade que você não tenha consentido, exceto conforme exigido
              por lei. A Proinddy NÃO venderá ou trocará dados pessoais para
              fins comerciais. Armazenamos seus dados pessoais apenas pelo tempo
              necessário para os fins aos quais são coletadas ou conforme
              exigido por lei. Apagamos ou destruímos os registros que contêm
              dados pessoais quando eles não forem mais necessários; isso será
              feito de maneira a proteger sua privacidade. Para períodos de
              armazenamento e exigências legais, consulte nossa{" "}
              <a href="#">Política de Segurança de Dados</a>.
            </h4>
            <li>2.6 PRECISÃO</li>
            <h4>
              É de sua responsabilidade informar à Proinddy sobre quaisquer
              alterações relevantes em seus dados pessoais, atualizando as
              informações da sua conta.
            </h4>
            <li>2.7 PROTEÇÕES</li>
            <h4>
              A Proinddy envida esforços em segurança para proteger a
              privacidade de todos os nossos registros, incluindo seus dados
              pessoais. Implementamos medidas de segurança física, comercial e
              técnica projetadas para prevenir e proteger contra perda ou roubo,
              bem como acesso, divulgação, cópia, uso ou modificação não
              autorizada de seus dados pessoais. Apenas os funcionários da
              Proinddy ou os seus prestadores de serviços cujas funções exigem
              requisição de acesso à seus dados pessoais possuem acesso aos
              mesmos, conforme descrito nas seções 1.3, 1.4 e 1.5. Todos estes
              funcionários são obrigados, como condição de emprego, a respeitar
              a confidencialidade das Informações Pessoais de nossos clientes.
              Nenhuma equipe poderá acessar, visualizar ou modificar seus dados,
              conforme descrito na seção 1.2. Utilizamos meios contratuais ou
              outros para garantir que haja um nível comparável de proteção para
              qualquer Informação Pessoal que seja processada por terceiros por
              nós.
            </h4>
            <li>2.8 ABERTURA E TRANSPARÊNCIA</li>
            <h4>
              Para que você possa ter certeza que estamos lidando com suas
              Informações Pessoais de maneira adequada, tomamos medidas
              extraordinárias para documentar nossas políticas e fornecer
              abertura e transparência às informações pessoais que coletamos,
              por que as coletamos e como as usamos, divulgamos e manipulamos.
              Para obter mais informações sobre nossas políticas e práticas com
              relação ao gerenciamento de suas informações pessoais, consulte
              nossa <a href="#">Política de Segurança de Dados</a>, e em caso de
              dúvidas, entre em contato conosco pelo{" "}
              <a href="#">suporte@proinddy.com.br</a>.
            </h4>
            <li>2.9 ACESSO INDIVIDUAL</li>
            <h4>
              Se a qualquer momento você tiver alguma dúvida sobre nossos
              registros que contêm seus dados, faremos o possível para
              respondê-las. Você tem o direito de ser informado sobre quais
              dados pessoais mantemos sobre você, como eles foram ou estão sendo
              usados e a quem foram ou podem ter sido divulgados, bem como o
              direito de acessar esses dados. Tais situações estão sujeitas a
              exceções limitadas, conforme determinado por lei.
            </h4>
            <h4>
              Quando você nos envia uma solicitação por escrito, confirmaremos
              sua identidade e forneceremos todas informações que temos sobre
              nosso armazenamento, uso e divulgação de seus dados pessoais.
            </h4>

            <h4>
              Você pode solicitar acesso às suas Informações Pessoais ou
              contestar sua precisão e integridade e solicitar alterações,
              conforme apropriado, entrando em contato conosco pelo{" "}
              <a href="#">suporte@proinddy.com.br</a>.
              <br />
              <br />
              Para mais detalhes, não deixe de consultar nossa{" "}
              <a href="#">Política de Segurança de Dados</a>.
            </h4>
            <li>2.10 ALTERAÇÕES DE NOSSA POLÍTICA DE PRIVACIDADE</li>
            <h4>
              Talvez seja necessário alterar nossa Política de Privacidade
              periodicamente e todas as atualizações serão publicadas em{" "}
              <a href="http://proinddy.com.br">http://proinddy.com.br</a>.
              Publicaremos uma data efetiva no topo da página para sua
              conveniência.
            </h4>

            <h4>
              A empresa nomeou como seu representante no brasil, nos termos do
              art. 41, § 1º da Lei nº 13.709/2018:
              <br />
              <br />
              Caio Anderson Pinheiro Santos
              <br />
              Proteção de Dados e Cyberlaw
              <br />
              Data Protection Officer – DPO
              <br />
              São Luís, MA, Brasil.
              <br />
              E-mail: <a href="">caio.aps@hotmail.com</a>
            </h4>
          </ul>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};

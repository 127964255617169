import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Header } from "../../../theme";
import { Container, Button } from "../../../components/common";

import {
  Wrapper,
  IntroWrapper,
  Details,
  ContainerButton,
  ContainerBlocks,
  Block,
} from "./styles";

//**imagens */
import canopus from "../../../assets/logos/canopus.png";
import invisa from "../../../assets/logos/invisa.png";
import acqua from "../../../assets/logos/acqua.jpg";
import aldenora from "../../../assets/logos/aldenora.jpg";
import lacmar from "../../../assets/logos/lacmar.jpeg";
import potiguar from "../../../assets/logos/potiguar.png";
import zoo from "../../../assets/logos/zoo.jpeg";
import depyl from "../../../assets/logos/depyl.jpg";
import mateus from "../../../assets/logos/mateus.png";
import mr from "../../../assets/logos/mr.png";
export const Intro = () => (
  <Wrapper>
    <Header />
    <IntroWrapper as={Container}>
      <Details>
        <h1>PROINDDY SERVIÇOS E COMERCIO</h1>
        <h4>Soluções em Ponto Eletrônico</h4>
        <ContainerButton>
          <Button as={AnchorLink} href="#contact">
            Contrate-me
          </Button>
        </ContainerButton>
        <ContainerButton style={{ marginTop: 50 }}>
          <h2 style={{ marginBottom: -2 }}>Alguns de nossos Clientes:</h2>
        </ContainerButton>

        <ContainerBlocks>
          <Block>
            <img src={canopus} alt="canopus" />
          </Block>
          <Block>
            <img src={invisa} alt="canopus" />
          </Block>
          <Block>
            <img src={acqua} alt="canopus" />
          </Block>
          <Block>
            <img src={aldenora} alt="canopus" />
          </Block>
          <Block>
            <img src={lacmar} alt="canopus" />
          </Block>
          <Block>
            <img src={potiguar} alt="canopus" />
          </Block>
          <Block>
            <img src={zoo} alt="canopus" />
          </Block>
          <Block>
            <img src={depyl} alt="canopus" />
          </Block>
          <Block>
            <img src={mateus} alt="canopus" />
          </Block>
          <Block>
            <img
              src={mr}
              alt="canopus"
              style={{ width: 85, height: 30, marginTop: 38, marginLeft: 8 }}
            />
          </Block>
        </ContainerBlocks>
      </Details>
    </IntroWrapper>
  </Wrapper>
);

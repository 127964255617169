import React from "react";
import { Container } from "../../../components/common";
import { Wrapper, Grid, Item, List } from "./styles";
import { SkillsWrapper, Details, Thumbnail } from "../Skills/styles";

//**imagens */
import app from "../../../assets/logos/app.jpg";
import sistema from "../../../assets/img/sistema.png";
import batidas from "../../../assets/img/app-batidas.png";
import suporte from "../../../assets/img/suporte.png";
import ah30 from "../../../assets/img/ah30.jpg";
import lite from "../../../assets/img/lite.png";

export const Projects = () => {
  return (
    <Wrapper as={Container} id="projects">
      <h2>Produtos e Serviços</h2>
      <Grid>
        <Item>
          <img src={app} alt="app" />
          <h3>
            <a
              href="https://play.google.com/store/apps/details?id=com.bateponto"
              target="_blank"
            >
              App Proinddy - Ponto Fácil
            </a>
          </h3>
          <p>
            Registro de ponto em celulares Android. Perfeito para a gestão de
            colaboradores externos. Com Geolocaliização e comprovante via
            WhatsApp.
          </p>
        </Item>
        <Item>
          <img src={sistema} alt="app" />
          <h3>Sistema de Ponto</h3>
          <p>
            Tenha Controle total. Faça todos os ajustes necessários. Importações
            de escala, lançamentos e sincronização automáticas das batidas.
          </p>
        </Item>
        <Item>
          <img src={batidas} alt="app" />
          <h3>
            <a
              href="https://play.google.com/store/apps/details?id=proinddy.com.br.batidas"
              target="_blank"
            >
              App Proinddy Batidas
            </a>
          </h3>
          <p>Acompanhe as batidas pelo app. Pesquisas por nome e setores.</p>
        </Item>
        <Item>
          <img src={suporte} alt="app" />
          <h3>Assitência e Suporte Técnico</h3>
          <p>
            Nossos clientes possuem assistencia técnica para o conserto de
            relogios de ponto, com mão-de-obra especializada e peças genuínas
            para reposição. Além disso, contam com o suporte técnico para
            solução de problemas com o gerenciamento de ponto eletrônico.
          </p>
        </Item>
      </Grid>
      <SkillsWrapper as={Container}>
        <Details>
          <h1>Relógio Ah30 Ahgora</h1>
          <p>Equipamento 100% confiável.</p>
          <List>
            <li>Equipamentos resistentes a ambientes hostis</li>
            <li>Opções com conexão ethernet, wi-fi ou rede 3G</li>
            <li>Impressora térmica com corte automático</li>
            <li>Tempo real: tecnologia Internet das Coisas (IoT)</li>
          </List>
        </Details>
        <Thumbnail>
          <img src={ah30} alt="ah30" />
        </Thumbnail>
      </SkillsWrapper>

      <SkillsWrapper as={Container} style={{ marginTop: -150 }}>
        <Thumbnail>
          <img src={lite} alt="ah30" />
        </Thumbnail>
        <Details>
          <h1>Registrador eletrônico aderente a portaria 373/MTE.</h1>
          <p>
            Com tecnologia IoT e grande autonomia. O nobreak interno garante o
            funcionamento mesmo com quedas de energia.
          </p>
          <List>
            <li>Biometria</li>
            <li>Mifare ou 125k</li>
            <li>Usuário e senha via teclado numérico</li>
          </List>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};

import React from "react";
import { Container } from "../../components/common";
import { Wrapper, Flex, Details } from "./styles";

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2>Proinddy Serviços e Comércio</h2>
        <span>
          © Todos os direitos são reservados | {new Date().getFullYear()} |{" "}
          <a>Proinddy Serviços e Comercio</a>
        </span>
      </Details>
    </Flex>
  </Wrapper>
);
